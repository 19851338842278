import _Api from '@/api/_Api';

export interface LoginDataInterface {
   email: string;
   password: string;
}

export class UserApi extends _Api {
   static async auth(loginData: LoginDataInterface): Promise<any> {
      const response = await this.http().post('users/auth', loginData);
      return response.data;
   }
}
