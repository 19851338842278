
import { Prop, Vue, Component } from 'vue-property-decorator';

@Component
export default class CopyHelper extends Vue {
   @Prop({ default: '', type: [String] }) clipboard!: string;
   @Prop({ default: 'bottom', type: [String] }) placement!: string;
   @Prop({ default: false, type: [Boolean] }) strip_tags!: string;

   copied: any = null;

   copySuccess(text: string) {
      this.copied = text;
      setTimeout(() => (this.copied = null), 2500);
   }
}
