import { localStorage } from '@/config/localStorage';

import { UsersInterface } from '@/interfaces/Users';

import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { UserApi } from '@/api/UserApi';

const state: UsersInterface = {
   id: null,
   email: null,
   token: localStorage.getAuthToken(),
   roles: localStorage.getItem('roles', []),
   status: null,
   username: localStorage.getItem('username', ''),
   settings: null,
   currency: null,
};

const mutations: MutationTree<UsersInterface> = {
   AUTH_REQUEST(state) {
      state.status = 'loading';
   },
   AUTH_SUCCESS(state, { token, roles, username }) {
      state.status = 'success';
      state.roles = roles;
      state.username = username;
      state.token = UserApi.setAuth(token);
      localStorage.setItem('roles', roles);
      localStorage.setItem('username', username);
   },
   AUTH_ERROR(state) {
      state.status = 'error';
      state.token = null;
   },
   AUTH_LOGOUT(state) {
      state.token = UserApi.setAuth(null);
   },
};

const getters: GetterTree<UsersInterface, any> = {
   isAuth(state) {
      return state.token !== null;
   },
};

const actions: ActionTree<UsersInterface, any> = {
   AUTH_REQUEST({ commit }, user) {
      return UserApi.auth(user).then(resp => {
         commit('AUTH_SUCCESS', resp);
         return true;
      });
   },

   AUTH_LOGOUT({ commit }) {
      return new Promise(resolve => {
         commit('AUTH_LOGOUT');
         resolve('logout');
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
