
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';

import { first, random, uniqBy } from 'lodash';

import { Chrome, Sketch, Compact } from 'vue-color';

import { defaultColors as defaultPallet } from '@/config/functions';

@Component({
   components: {
      'chrome-picker': Chrome,
      'sketch-picker': Sketch,
      'compact-picker': Compact,
      'compact-multi-picker': () => import('@/components/helpers/_color-picker/CompactMulti.vue'),
   },
})
export default class ColorPicker extends Vue {
   @Prop({ type: String, default: 'compact' }) picker!: 'chrome' | 'compact' | 'compact-multi' | 'sketch';

   @Prop({ type: Array, default: () => defaultPallet }) palette!: string[];

   @Prop({ type: String, default: '' }) reset_color!: string;

   @Prop({ type: String, default: '' }) label!: string;

   @Prop({ type: [String, Array], default: '' }) color!: string | string[];

   @Prop({ type: [String, Array], default: '' }) value!: string | string[];

   @Prop({ type: [Boolean], default: false }) noInput!: boolean;

   displayPicker: boolean = false;

   colorBox: string = '';

   filteredPallet() {
      return this.palette.filter(p => p && p.length);
   }

   mounted(): void {
      this.onChangeValue(this.value || this.color);
   }

   updateFromPicker(color: string | string[]) {
      color = this.updateColors(color);
      this.$emit('input', Array.isArray(color) ? color.map(c => c.slice(1, 7)) : color.slice(1, 7));

      if (this.picker != 'compact-multi') {
         this.togglePicker();
      }
   }

   documentClick(e: Event) {
      let el = this.$refs.colorpicker as HTMLElement;
      let target = e.target as HTMLElement;

      if (el && el !== target && typeof el.contains != 'undefined' && !el.contains(target)) {
         this.hidePicker();
      }
   }

   showPicker() {
      document.addEventListener('click', this.documentClick);
      this.displayPicker = true;
   }

   hidePicker() {
      document.removeEventListener('click', this.documentClick);
      this.displayPicker = false;
   }

   togglePicker() {
      this.displayPicker ? this.hidePicker() : this.showPicker();
   }

   updateColors(color: string | string[]) {
      color = !Array.isArray(color) ? [color] : color;
      color = color.map((c: any) => (typeof c !== 'string' ? c?.hex : c));

      let colors = [];

      for (const hex of color) {
         if (hex.slice(0, 1) === '#') {
            colors.push({ hex });
         } else if (hex.slice(0, 4) === 'rgba') {
            const rgba = hex.replace(/^rgba?\(|\s+|\)$/g, '').split(',');
            colors.push({
               hex: '#' + ((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1),
               a: rgba[3],
            });
         } else if (hex.slice(0, 1) !== '#') {
            colors.push({ hex: '#' + hex });
         }
      }

      colors = uniqBy(colors, 'hex');
      colors = this.picker !== 'compact-multi' ? (first(colors) as any) : colors;

      return !Array.isArray(colors) ? colors?.hex : colors.map(({ hex }) => hex);
   }

   @Watch('value')
   @Watch('color')
   onChangeValue(color: string | string[]) {
      const border = this.noInput ? '4px' : '0px 4px 4px 0px';
      const css = [`border-radius: ${border}`];

      const colors = this.updateColors(color);

      if (!Array.isArray(colors)) {
         css.push(`background-color: ${colors}`);
      } else if (Array.isArray(colors) && colors.length === 1) {
         css.push(`background-color: ${colors[0]}`);
      } else {
         const gradient = colors
            .map((c, i) => {
               const bg = (100 / colors.length) * (i + 1);
               return c + ' ' + (bg / 1.2).toFixed(2) + '%';
            })
            .join(', ');
         const degs = [30, 60, 90, 110, 140, 160, 180, 210, 230, 260, 290, 320];
         const deg = degs[random(0, degs.length)];
         css.push(`background: linear-gradient(${deg}deg, ${gradient})`);
      }

      this.colorBox = css.join(';');
   }
}
