import _Api from '@/api/_Api';

import { CurrenciesInterface } from '@/interfaces/Currencies';

export class SettingsApi extends _Api {
   static async currencies(): Promise<CurrenciesInterface> {
      const response = await this.http().get('settings/currencies');
      return response.data;
   }

   static async tarification(): Promise<any> {
      const response = await this.http().get('settings/tarification');
      return response.data;
   }

   static async vat(): Promise<any> {
      const response = await this.http().get('settings/vat');
      return response.data;
   }

   static async beneficiaries(): Promise<any> {
      const response = await this.http().get('settings/beneficiaries');
      return response.data;
   }

   static async beneficiary_account(id: number): Promise<any> {
      const response = await this.http().get('settings/beneficiaries/' + id);
      return response.data;
   }
}
