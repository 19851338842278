import Vue from 'vue';

export const expires: number = 72 * 3600;

export interface localStorageInterface {
   keyPrepare(key: string): string;
   allItems(): any;
   getItem(key: string, def?: any): any;
   setItem(key: string, value?: any, expires?: any, unit?: string): void;
   removeItem(key: string): void;
   getAuthToken(renew?: boolean): string | null;
   setAuthToken(token: string | null): string | null;
}

export const localStorage: localStorageInterface = {
   keyPrepare(key: string) {
      return key;
   },

   allItems() {
      return window.localStorage;
   },

   getItem(key: string, def: any = null): any {
      const data = window.localStorage.getItem(key);

      if (data === undefined || data === null) {
         return def;
      }

      const object = JSON.parse(data);

      if (object && Object.keys(object).length) {
         if (object.expires == null || object.expires <= 0 || object.expires >= new Date().valueOf()) {
            return object.value;
         } else {
            this.removeItem(this.keyPrepare(key));
         }
      }

      return def;
   },

   setItem(key: string, value: any = null, expires: any = 0, unit: string = 'seconds'): void {
      if (expires > 0) {
         const dt = new Date();

         switch (unit) {
            case 'ms':
            case 'milliseconds':
               dt.setMilliseconds(dt.getMilliseconds() + expires);
               break;
            case 's':
            case 'seconds':
               dt.setSeconds(dt.getSeconds() + expires);
               break;
            case 'm':
            case 'minutes':
               dt.setMinutes(dt.getMinutes() + expires);
               break;
         }

         expires = dt.valueOf();
      } else {
         expires = null;
      }

      window.localStorage.setItem(
         key,
         JSON.stringify({
            value: value,
            createdAt: new Date().valueOf(),
            expires: expires,
         })
      );
   },

   removeItem(key: string): void {
      window.localStorage.removeItem(key);
   },

   getAuthToken(renew: boolean = true): string | null {
      const token = this.getItem('auth', null);
      if (token && renew) this.setItem('auth', token, expires);
      return token;
   },

   setAuthToken(token: string | null): string | null {
      this.setItem('auth', token, expires);
      return token;
   },
};

export default {
   install(vue: typeof Vue) {
      vue.prototype.$localStorage = localStorage;
   },
};
