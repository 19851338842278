import _Api from '@/api/_Api';

import { BookingInterface, GetFilesInterface } from '@/interfaces/Bookings';

import { OrderInterface, OrderActivityInterface, UpdateOrderActivitiesInterface, InvoiceInterface, CreateReportInterface } from '@/interfaces/Order';

export class BookingsApi extends _Api {
   static async find(params: any): Promise<BookingInterface[]> {
      const response = await this.http().get('calendar', { params });
      return response.data;
   }

   static async orderList(params: any): Promise<OrderInterface[]> {
      const response = await this.http().get('orders', { params });
      return response.data;
   }

   static async allOrdersList(params: any): Promise<OrderInterface[]> {
      const response = await this.http().get('orders/list', { params });
      return response.data;
   }

   static async orderOne(id: number, fields: string[] = []): Promise<OrderInterface> {
      const response = await this.http().get('orders/' + id, { params: { fields } });
      return response.data;
   }

   static async checkUserPresence(params: any): Promise<any[]> {
      const response = await this.http().post('orders/check-user-presence', { params });
      return response.data;
   }

   static async createOrder(): Promise<OrderInterface> {
      const response = await this.http().put('orders');
      return response.data;
   }

   static async saveOrder(id: number, fields: OrderActivityInterface | UpdateOrderActivitiesInterface): Promise<OrderInterface> {
      const response = await this.http().post('orders/' + id, fields);
      return response.data;
   }

   static async activitiesList(params: any): Promise<any> {
      const response = await this.http().post('orders/activities-list', params);
      return response.data;
   }

   static async changePaymentStatus(params: any, type: string = 'act'): Promise<any> {
      const response = await this.http().post('orders/change-payment-status', params, { params: { type } });
      return response.data;
   }

   static async removeOrder(id: number): Promise<OrderActivityInterface> {
      return this.setObjectStatus(id, id, 575);
   }

   static async restoreOrder(id: number): Promise<OrderActivityInterface> {
      return this.setObjectStatus(id, id, 576);
   }

   static async setObjectStatus(id: number, object_id: number, status: number): Promise<any> {
      const response = await this.http().put('orders/' + id, { status, object_id });
      return response.data;
   }

   static async createInvoice(order: number, invoice: any): Promise<OrderInterface> {
      const response = await this.http().post('orders/' + order + '/invoice', invoice);
      return response.data;
   }

   static async updateInvoice(order: number, invoice: any): Promise<OrderInterface> {
      const response = await this.http().put('orders/' + order + '/invoice', invoice);
      return response.data;
   }

   static async downloadInvoice(order: number, invoice: number): Promise<InvoiceInterface> {
      const response = await this.http().get('orders/' + order + '/invoice/' + invoice);
      return response.data;
   }

   static async createReport(id: number, data: any): Promise<CreateReportInterface> {
      const response = await this.http().post('orders/' + id + '/create-report', data);
      return response.data;
   }

   static async getFiles(id: number): Promise<GetFilesInterface> {
      const response = await this.http().get('orders/' + id + '/files');
      return response.data;
   }

   static async uploadFiles(id: number, data: any): Promise<any> {
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const $form = new FormData();

      data.data.forEach((file: any) => {
         $form.append('files[]', file);
      });

      const response = await this.http().post('orders/' + id + '/upload-files', $form, config);
      return response.data;
   }

   static async deleteFile(groupId: number, fileId: number): Promise<any> {
      const response = await this.http().delete('orders/' + groupId + '/' + fileId + '/delete-file');
      return response.data;
   }
}
