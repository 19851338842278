<template>
   <b-button variant="outline-primary" @click="logout">
      <span class="icon-off"></span>
   </b-button>
</template>

<script>
export default {
   name: 'Logout',
   methods: {
      logout() {
         this.$bvModal
            .msgBoxConfirm('Do you want logout?', {
               title: 'Please Confirm',
               size: 'sm',
               buttonSize: 'sm',
               okVariant: 'danger',
               okTitle: 'YES',
               cancelTitle: 'NO',
               footerClass: 'p-2',
               hideHeaderClose: false,
               centered: true,
            })
            .then(value => {
               if (value) {
                  this.$store.dispatch('users/AUTH_LOGOUT').then(() => {
                     this.$router.push({ name: 'auth' });
                  });
               }
            });
      },
   },
};
</script>

<style scoped></style>
