<template>
   <div id="app" class="h-100">
      <router-view :key="$route.path" />

      <b-modal id="auth-modal" @hide="routeToAuth" ok-only>
         <p class="my-4">The session has expired since you have been inactive for {{ logoutTime }}.</p>
      </b-modal>
   </div>
</template>
<script>
import { mapGetters } from 'vuex';

import { expires } from '@/config/localStorage';

export default {
   name: 'app-main',
   computed: {
      ...mapGetters({
         isAuth: 'users/isAuth',
      }),
   },
   data() {
      return {
         logoutTime: expires / 3600 + ' hours',
      };
   },
   metaInfo() {
      return {
         title: '',
         titleTemplate: '%s - M.O.T.O',
      };
   },
   created() {
      this.$http.interceptors.response.use(
         response => {
            if (!response.data.loading) {
               this.$store.commit('hideLoading');
            }
            return typeof response.data == 'string' ? Promise.reject() : response;
         },
         ({ response }) => {
            this.$store.commit('hideLoading');

            if (!response) {
               return;
            }

            const { data, status } = response;
            const { errors } = data;

            if (data !== undefined && data.errors !== undefined) {
               errors.forEach(error => {
                  this.$bvToast.toast(error, {
                     title: 'Error',
                     variant: 'danger',
                     solid: true,
                  });
               });
            } else if (status >= 400 && typeof data != 'string' && Object.keys(data).length) {
               Object.values(data).forEach(error => {
                  this.$bvToast.toast(error, {
                     title: 'Error',
                     variant: 'danger',
                     solid: true,
                  });
               });
            }

            if (typeof status !== 'undefined') {
               if (status === 401) {
                  this.$store.commit('users/AUTH_LOGOUT');
                  this.checkAuth();
               }

               if (status !== 200) {
                  return Promise.reject(data);
               }
            }

            if (status >= 400) {
               return Promise.reject(response);
            }
         }
      );
   },

   mounted() {
      this.checkAuth();
   },

   methods: {
      checkAuth() {
         const token = this.$localStorage.getAuthToken(false);

         if (token && this.$router.history.current.name === 'auth') {
            this.$router.push({ name: 'calendar' });
         }

         if (
            !token &&
            this.$router.history.current.path !== '/' &&
            !['auth', 'activities.report', 'accommodation.report', 'hotels.rates.checker'].includes(this.$router.history.current.name)
         ) {
            this.$store.dispatch('users/AUTH_LOGOUT').then(() => {
               this.$bvModal.show('auth-modal');
            });
         }

         setTimeout(() => this.checkAuth(), 10 * 1000);
      },
      routeToAuth() {
         this.$bvModal.hide('auth-modal');

         if (this.$router.history.current.path !== '/') {
            this.$router.push({ name: 'auth' });
         }
      },
   },
   watch: {
      isAuth(n, o) {
         if (n && !o) {
            this.$store.dispatch('init');
            this.checkAuth();
         }
      },
   },
};
</script>
