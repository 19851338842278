import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { SettingsApi } from '@/api/SettingsApi';

const state = {
   currencies: [],
   tarification: [],
   vat: [],
};

const mutations: MutationTree<any> = {
   setItems(state, { name, value }) {
      state[name] = value;
   },
};

const getters: GetterTree<any, any> = {};

const actions: ActionTree<any, any> = {
   SET_CURRENCIES({ commit, state }) {
      if (state.currencies.length) {
         return Promise.resolve(Object.keys(state.currencies).length);
      }
      return SettingsApi.currencies().then(value => {
         commit('setItems', { value, name: 'currencies' });
         return Object.keys(value).length;
      });
   },
   SET_tarification({ commit, state }) {
      if (state.tarification.length) {
         return Promise.resolve(state.tarification.length);
      }
      return SettingsApi.tarification().then(value => {
         commit('setItems', { value, name: 'tarification' });
         return value.length;
      });
   },
   SET_VAT({ commit, state }) {
      if (state.vat.length) {
         return Promise.resolve(state.vat.length);
      }
      return SettingsApi.vat().then(value => {
         commit('setItems', { value, name: 'vat' });
         return value.length;
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
