
import { Vue, Component, Prop } from 'vue-property-decorator';
/*import mapValues from 'lodash/mapValues';

const currencies = mapValues(require('currency-formatter/currencies.json'), (c: any) => ({
   ...c,
   symbolPosition: c.symbolOnLeft ? 'front' : '',
   fractionCount: c.decimalDigits,
}));*/

@Component
export default class CurrencyHelper extends Vue {
   @Prop({ default: true, type: [Number] }) sum!: number;
   @Prop({ default: 2, type: [Number] }) fractionCount!: number;
   @Prop({ type: [String] }) currency: string | undefined;

   userCurrency!: string;

   currencySettings: object = {
      fractionCount: this.fractionCount,
   };
}
