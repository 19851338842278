import Vue from 'vue';

import Vuex from 'vuex';

import users from './_users';

import hotels from '@/store/_hotels';

import prices from '@/store/_prices';

import activities from '@/store/_activities';

import beneficiaries from '@/store/_beneficiaries';

import staff from '@/store/_staff';

Vue.use(Vuex);

const state = {
   loader: null,
   ready: true,
};

const mutations = {
   showLoading(state: any, params = {}) {
      if (state.loader !== null) {
         state.loader.hide();
      }
      // @ts-ignore
      state.loader = this._vm.$loading.show(params);
   },
   hideLoading(state: any) {
      if (state.ready) {
         if (state.loader !== null) {
            state.loader.hide();
         }
         state.loader = null;
      }
   },
   setItems(state: any, data: any) {
      state[data.name] = data.value;
   },
};

export default new Vuex.Store({
   state,
   mutations,
   actions: {
      init({ dispatch }) {
         //dispatch('interval');
      },

      interval({ dispatch, commit, getters }) {
         if (!getters['users/isAuth']) {
            commit('hideLoading');
            commit('setItems', { name: 'ready', value: true });
            return Promise.reject();
         }

         commit('showLoading');
         commit('setItems', { name: 'ready', value: false });

         return Promise.all([dispatch('activities/GET_ALL_OBJECTS'), dispatch('activities/GET_DICTIONARIES')])
            .then((result: number[]) => {
               if (Math.min(...result) === 0) {
                  setTimeout(() => dispatch('interval'), 500);
               } else {
                  commit('hideLoading');
                  commit('setItems', { name: 'ready', value: true });
               }
               return true;
            })
            .catch(() => {
               return dispatch('interval');
            });
      },
   },
   modules: {
      users,
      hotels,
      prices,
      staff,
      activities,
      beneficiaries,
   },
});
