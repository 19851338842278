import _Api from '@/api/_Api';

export class StaffApi extends _Api {
   static async agents(): Promise<any> {
      const response = await this.http().get('staff/agents');
      return response.data;
   }

   static async drivers(): Promise<any> {
      const response = await this.http().get('staff/drivers');
      return response.data;
   }

   static async guides(): Promise<any> {
      const response = await this.http().get('staff/guides');
      return response.data;
   }

   static async services(): Promise<any[]> {
      const response = await this.http().get('staff/services');
      return response.data;
   }
}
