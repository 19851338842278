<template>
   <header :class="{ 'pb-3': true, 'position-fixed': fixed }">
      <b-row>
         <b-col xl="9" md="12" sm="12" v-if="!user_roles.includes(34)">
            <b-button v-if="'edit.order' !== $route.name && !user_roles.includes(38)" @click="createOrder" variant="outline-primary" class="mr-3">
               New
            </b-button>

            <b-button v-if="'edit.order' === $route.name && !is_archived" variant="outline-primary" class="mr-3" @click="$emit('click-save')">
               <span class="icon-floppy"></span>
            </b-button>

            <b-button
               v-if="'edit.order' === $route.name && !user_roles.includes(38)"
               @click="$emit('new-order')"
               variant="outline-primary"
               class="mr-3"
            >
               New
            </b-button>

            <b-button v-if="'edit.order' === $route.name" @click="$emit('check-activities')" variant="outline-primary" class="mr-3">
               Check
            </b-button>

            <b-dropdown
               v-if="'edit.order' === $route.name && !user_roles.includes(38)"
               id="reports-dropdown"
               text="Reports"
               variant="outline-primary"
               class="mr-3"
            >
               <b-dropdown id="invoice-breakdown" dropright text="Invoice breakdown" variant="outline-none">
                  <b-dropdown-item @click="$root.$emit('header::create-report', 'INVBRKDWN', 'pdf')">PDF</b-dropdown-item>
                  <b-dropdown-item @click="$root.$emit('header::create-report', 'INVBRKDWN', 'docx')">DOCX</b-dropdown-item>
               </b-dropdown>
               <b-dropdown id="itinerary-for-client" dropright text="Itinerary for client" variant="outline-none">
                  <b-dropdown-item @click="$root.$emit('header::create-report', 'ITINERCLIEN', 'pdf')">PDF</b-dropdown-item>
                  <b-dropdown-item @click="$root.$emit('header::create-report', 'ITINERCLIEN', 'docx')">DOCX</b-dropdown-item>
               </b-dropdown>
               <b-dropdown-item
                  @click="$root.$emit('header::create-report', 'ITINERCLIEN', 'pdf', 'view')"
                  class="pl-1"
                  link-class="pl-2 text-uppercase"
               >
                  Itinerary for Agent
               </b-dropdown-item>
               <b-dropdown-item @click="$root.$emit('header::supplier-reminders')" class="pl-1" link-class="pl-2 text-uppercase">
                  <b-icon icon="chat-dots" /> Supplier Reminders
               </b-dropdown-item>
               <b-dropdown-item @click="$root.$emit('header::get-templates')" class="pl-1" link-class="pl-2 text-uppercase">
                  <b-icon icon="chat-dots" /> Templates
               </b-dropdown-item>
            </b-dropdown>

            <b-button :to="{ name: 'activities' }" :variant="$route.name !== 'activities' ? 'outline-primary' : 'primary'" class="mr-3">
               Activities report
            </b-button>

            <b-button :to="{ name: 'accommodations' }" :variant="$route.name !== 'accommodations' ? 'outline-primary' : 'primary'" class="mr-3">
               Accommodation report
            </b-button>

            <b-button :to="{ name: 'transfers' }" :variant="$route.name !== 'transfers' ? 'outline-primary' : 'primary'" class="mr-3">
               Transfer report
            </b-button>

            <b-button
               v-if="!user_roles.includes(38)"
               :to="{ name: 'calendar' }"
               :variant="$route.name !== 'calendar' ? 'outline-primary' : 'primary'"
               class="mr-3"
            >
               Calendar
            </b-button>

            <b-button
               v-if="!user_roles.includes(38)"
               :to="{ name: 'orders' }"
               :variant="$route.name !== 'orders' ? 'outline-primary' : 'primary'"
               class="mr-3"
            >
               order list
            </b-button>

            <b-button
               v-if="!user_roles.includes(38)"
               :to="{ name: 'archive' }"
               :variant="$route.name !== 'archive' ? 'outline-primary' : 'primary'"
               class="mr-3"
            >
               archive
            </b-button>

            <b-button
               v-if="!user_roles.includes(38) && 'edit.order' === $route.name && !is_archived"
               @click="$emit('remove-order')"
               variant="danger"
               class="mr-3"
            >
               delete to archive
            </b-button>

            <b-button
               v-if="!user_roles.includes(38) && 'edit.order' === $route.name && is_archived"
               @click="$emit('restore-order')"
               variant="success"
               class="mr-3"
            >
               restore from archive
            </b-button>
         </b-col>
         <b-col xl="3" md="12" sm="12" class="header-search">
            <b-row class="justify-content-end pr-3">
               <b-col cols="2" offset="1">
                  <b-button @click="goTo('/mobile/home')" class="p-0" variant="outline-primary"><span class="icon-mobile"></span></b-button>
               </b-col>
               <b-col cols="6">
                  <b-input-group>
                     <b-form-input placeholder="Enter Lead Guest or Our Ref" v-model="searchValue" @keydown.enter="onSearchItem()"></b-form-input>
                     <b-input-group-append>
                        <b-button size="sm" @click="onSearchItem()" variant="outline-primary"><span class="icon-search"></span></b-button>
                     </b-input-group-append>
                  </b-input-group>
               </b-col>
               <b-col :cols="text_before_logout && is_archived ? 2 : 1" class="text-nowrap">
                  <span v-if="text_before_logout && is_archived" class="pr-3 text-danger">
                     {{ text_before_logout }}
                  </span>
                  <logout />
               </b-col>
            </b-row>
         </b-col>
      </b-row>
   </header>
</template>

<script>
import { mapState } from 'vuex';
import { BookingsApi } from '@/api/BookingsApi';
import { ActivitiesApi } from '@/api/ActivitiesApi';

export default {
   name: 'Header',
   props: {
      is_archived: {
         type: Boolean,
         default: false,
      },
      fixed: {
         type: Boolean,
         default: false,
      },
      text_before_logout: {
         type: String,
         default: undefined,
      },
   },
   computed: {
      ...mapState({
         user_roles: s => s.users.roles,
      }),
   },
   data() {
      return {
         searchValue: '',
         isDropdown2Visible: false,
      };
   },
   mounted() {
      this.$root.$on('bv::dropdown::show', bvEvent => {
         if (['invoice-breakdown', 'itinerary-for-client'].includes(bvEvent.componentId)) {
            this.isDropdown2Visible = true;
         }
      });
      this.$root.$on('bv::dropdown::hide', bvEvent => {
         if (['invoice-breakdown', 'itinerary-for-client'].includes(bvEvent.componentId)) {
            this.isDropdown2Visible = false;
         }
         if (this.isDropdown2Visible) {
            bvEvent.preventDefault();
         }
      });
   },
   methods: {
      rootEmmit(event, data) {
         this.vm.$root.$emit(event, data);
      },
      createOrder() {
         BookingsApi.createOrder().then(({ id }) => {
            this.$router.push({ name: 'edit.order', params: { id } });
         });
      },
      goTo(to) {
         this.$router.push(to);
      },
      onSearchItem() {
         if (this.searchValue != '') {
            const params = {
               searchValue: this.searchValue,
            };
            ActivitiesApi.findGroupByRef(params).then(result => {
               if (result.moto_id != 'Not found') {
                  let id = result.moto_id;
                  this.$router.push({ name: 'edit.order', params: { id } }).catch(() => {});
               } else {
                  this.$bvToast.toast('Not found', {
                     title: 'Not found',
                     variant: 'info',
                     solid: true,
                  });
               }
            });
         }
      },
   },
};
</script>

<style lang="scss">
header {
   .btn {
      text-transform: uppercase;
   }
}
</style>
