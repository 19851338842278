import Axios, { AxiosStatic } from 'axios';

import Vue from 'vue';

import { baseURL } from '@/config/index';

import { localStorage } from '@/config/localStorage';

const http = Axios.create({
   baseURL,
});

//http.defaults.headers.common['x-language'] = locale;
http.defaults.headers.common['Authorization'] = localStorage.getAuthToken();

export default {
   install(vue: typeof Vue) {
      vue.prototype.$http = http;
   },
};

export const $http = http;

declare module 'vue/types/vue' {
   interface Vue {
      $http: AxiosStatic;
   }
}
