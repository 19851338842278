
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';

@Component
export default class InputTooltip extends Vue {
   @Prop() private value!: string;
   @Prop({ default: true }) private is_input!: boolean;
   @Prop({ default: true }) private saveOnHide!: boolean;

   text: string = '';
   changed: boolean = false;

   mounted(): void {
      this.text = this.value;
   }

   @Watch('text')
   onChangeText(n: string, o: string) {
      if (o !== n) this.changed = true;
   }

   hide() {
      if (this.saveOnHide) this.save();
   }

   save() {
      if (this.changed) {
         this.$emit('change', this.text);
         (this.$refs as any).InputTooltip.hide(true);
      }
   }
}
