
import { Component, Watch, Vue, Prop } from 'vue-property-decorator';

import { customFormatter } from '@/config/functions';

@Component
export default class DatePickerRange extends Vue {
   @Prop({ type: [Array] }) value!: Date[];
   @Prop({ type: Boolean, default: false }) short!: Boolean;

   calendarShortcutsText: string = 'Select date range';

   calendarShortcuts: any[] = [
      {
         text: 'Today',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .subtract(2, 'M')
                  .format('YYYY-MM-') + '01'
            ).toDate();

            const $stop = this.$moment()
               .add(6, 'M')
               .subtract(1, 'd')
               .toDate();

            return [$start, $stop];
         },
         onClick: () => this.setDate('Today'),
      },
      {
         text: 'Last Month Start to Next Month End',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .subtract(1, 'M')
                  .format('YYYY-MM-') + '01'
            ).toDate();

            const $stop = this.$moment()
               .add(1, 'M')
               .subtract(1, 'd')
               .toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'This Month',
         range: () => {
            const $start = this.$moment(this.$moment().format('YYYY-MM-') + '01').toDate();

            const $stop = this.$moment($start)
               .add(1, 'M')
               .subtract(1, 'd')
               .toDate();

            return [$start, $stop];
         },
         onClick: () => this.setDate('This Month'),
      },
      {
         text: 'Next Month',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .add(1, 'M')
                  .format('YYYY-MM-') + '01'
            ).toDate();

            const $stop = this.$moment($start)
               .add(1, 'M')
               .subtract(1, 'd')
               .toDate();

            return [$start, $stop];
         },
         onClick: () => this.setDate('Next Month'),
      },
      {
         text: 'Previous Month',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .subtract(1, 'M')
                  .format('YYYY-MM-') + '01'
            ).toDate();

            const $stop = this.$moment($start)
               .add(1, 'M')
               .subtract(1, 'd')
               .toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'This Month Start to Current Date',
         range: () => {
            const $start = this.$moment(this.$moment().format('YYYY-MM-') + '01').toDate();
            const $stop = this.$moment().toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'Current Date to End of Month',
         range: () => {
            const $start = this.$moment().toDate();
            const $stop = this.$moment(
               this.$moment()
                  .add(1, 'M')
                  .format('YYYY-MM-') + '01'
            ).toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'Beginning of Month to End of Year',
         range: () => {
            const $start = this.$moment(this.$moment().format('YYYY-MM-') + '01').toDate();
            const $stop = this.$moment(this.$moment().format('YYYY-') + '12-31').toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'Beginning of Previous Month to End of Year',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .subtract(1, 'M')
                  .format('YYYY-MM-') + '01'
            ).toDate();

            const $stop = this.$moment(this.$moment().format('YYYY-') + '12-31').toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'Beginning of Month to End of Next Year',
         range: () => {
            const $start = this.$moment(this.$moment().format('YYYY-MM-') + '01').toDate();
            const $stop = this.$moment(
               this.$moment()
                  .add(1, 'Y')
                  .format('YYYY-') + '12-31'
            ).toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'This Year',
         range: () => {
            const $start = this.$moment(this.$moment().format('YYYY-') + '01-01').toDate();
            const $stop = this.$moment(this.$moment().format('YYYY-') + '12-31').toDate();

            return [$start, $stop];
         },
         onClick: () => this.setDate('This Year'),
      },
      {
         text: 'Next Year',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .add(1, 'Y')
                  .format('YYYY-') + '01-01'
            ).toDate();

            const $stop = this.$moment(
               this.$moment()
                  .add(1, 'Y')
                  .format('YYYY-') + '12-31'
            ).toDate();

            return [$start, $stop];
         },
         onClick: () => {
            this.setDate('Next Year');
         },
      },
      {
         text: 'Previous Year',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .subtract(1, 'Y')
                  .format('YYYY-') + '01-01'
            ).toDate();

            const $stop = this.$moment(
               this.$moment()
                  .subtract(1, 'Y')
                  .format('YYYY-') + '12-31'
            ).toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'This Year Start to Current Date',
         range: () => {
            const $start = this.$moment(
               this.$moment()
                  .subtract(1, 'Y')
                  .format('YYYY-') + '01-01'
            ).toDate();

            const $stop = this.$moment().toDate();

            return [$start, $stop];
         },
      },
      {
         text: 'Current Date to End of Year',
         range: () => {
            const $start = this.$moment().toDate();
            const $stop = this.$moment(this.$moment().format('YYYY-') + '12-31').toDate();

            return [$start, $stop];
         },
      },
   ];

   dateRange: Date[] = [];

   customFormatter = customFormatter;

   mounted(): void {
      this.setDate(this.value);
      this.dateRange = this.value;
   }

   setDate(input: string | Date[]) {
      this.calendarShortcuts.forEach(shortcut => {
         const range = shortcut.range();

         if (typeof input == 'string' && input == shortcut.text) {
            this.changeDate(shortcut);
         } else if (
            Array.isArray(input) &&
            Array.isArray(range) &&
            this.$moment(range[0]).format('DD.MM.YYYY') == this.$moment(input[0]).format('DD.MM.YYYY') &&
            this.$moment(range[1]).format('DD.MM.YYYY') == this.$moment(input[1]).format('DD.MM.YYYY')
         ) {
            this.changeDate(shortcut);
         }
      });
   }

   changeDate(shortcut: any) {
      this.dateRange = shortcut.range();
      this.calendarShortcutsText = shortcut.text;
   }

   @Watch('dateRange', { immediate: true, deep: true })
   onChangeDateRange(n: Date[], o: Date[]) {
      if (
         Array.isArray(n) &&
         (!Array.isArray(o) ||
            !o.length ||
            this.$moment(n[0]).format('DD.MM.YYYY') != this.$moment(o[0]).format('DD.MM.YYYY') ||
            this.$moment(n[1]).format('DD.MM.YYYY') != this.$moment(o[1]).format('DD.MM.YYYY'))
      ) {
         this.$emit(
            'input',
            n.map(date => (typeof date == 'string' ? date : date.toISOString()))
         );
         this.$emit('set-range', n);
      }
   }

   @Watch('calendarShortcutsText')
   onChangeCalendarShortcutsText(text: string) {
      this.$localStorage.setItem('calendar.calendarShortcutsText', text);
   }
}
