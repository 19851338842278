import _Api from '@/api/_Api';

import {
   AccommodationsRoomTypeParams,
   AccommodationsRoomTypeResponse,
   AccommodationsDataByDateParams,
   AccommodationsDataByDateResponse,
   AccommodationsDataResponse,
   allAccByUuidParams,
   allAccByUuidResponse,
} from '@/interfaces/Hotels';

export class HotelsApi extends _Api {
   static async all(): Promise<any> {
      const response = await this.http().get('hotels');
      return response.data;
   }

   static async calendar(): Promise<any> {
      const response = await this.http().get('hotels/calendar');
      return response.data;
   }

   static async roomTypes(): Promise<any> {
      const response = await this.http().get('hotels/room-types');
      return response.data;
   }

   static async rooms(): Promise<any> {
      const response = await this.http().get('hotels/rooms');
      return response.data;
   }

   static async accommodationsData(): Promise<AccommodationsDataResponse> {
      const response = await this.http().get('hotels/accommodations-data');
      return response.data;
   }

   static async getAccommodationsData(params: any): Promise<any> {
      const response = await this.http().post('hotels/accommodations-data', params);
      return response.data;
   }

   static async getAccommodationsDataByDate(params: AccommodationsDataByDateParams): Promise<AccommodationsDataByDateResponse> {
      const response = await this.http().get('hotels/accommodations-data-by-date', { params });
      return response.data;
   }

   static async getAccommodationsRoomType(params: AccommodationsRoomTypeParams): Promise<AccommodationsRoomTypeResponse> {
      const response = await this.http().get('hotels/accommodations-room-type', { params });
      return response.data;
   }

   static async allAccByUuid(params: allAccByUuidParams): Promise<allAccByUuidResponse> {
      const response = await this.http().post('hotels/all-accommodations-by-uuid', params);
      return response.data;
   }

   static async acceptAccommodation(params: any): Promise<any> {
      const response = await this.http().post('hotels/accept-accommodation', params);
      return response.data;
   }

   static async rejectAccommodation(params: any): Promise<any> {
      const response = await this.http().post('hotels/reject-accommodation', params);
      return response.data;
   }
}
