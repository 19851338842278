import moment from 'moment';

import { sortBy, first } from 'lodash';

import numeral from 'numeral';

// @ts-ignore
import Time from '@flat/time';

export function buildDropDown(obj: any[], name_column: string | number | null = null, sort_by = 'text', value_column: string | number | null = null) {
   return sortBy(
      obj.map((h: any) => ({
         ...h,
         value: value_column ? h[value_column] : h.id ?? h.o_id ?? h.d_id,
         text: (name_column ? h[name_column] : h.o_name ?? h.d_name).replace(/&amp;/gi, '&'),
      })),
      sort_by
   );
}

export const customFormatter: any = {
   stringify: (date: string | Date, f: any) => {
      return date ? moment(date, 'LLLL').format('DD.MM.YYYY') : '';
   },
   parse: (date: string, f: any) => {
      date = date.replace(/\//g, '.');
      if (/^[\d]{2}[.,]?[\d]{2}[.,]?[\d]{2}$/.test(date)) {
         date = date.replace(/^([\d]{2})[.,]?([\d]{2})[.,]?([\d]{2})$/, '$1.$2.20$3');
      }

      if (/^[\d]{2}[.,]?[\d]{2}[.,]?[\d]{4}$/.test(date)) {
         date = date.replace(/^([\d]{2})[.,]?([\d]{2})[.,]?([\d]{4})$/, '$1.$2.$3');
      }
      return date ? moment(date, /^[\d]{2}\.[\d]{2}\.[\d]{4}$/.test(date) ? 'DD.MM.YYYY' : 'YYYY-MM-DD').toDate() : date;
   },
   dateFormat: 'DD.MM.YYYY',
   datepicker: {
      formatLocale: {
         firstDayOfWeek: 1,
         months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
         monthsShort: [
            'Jan (01)',
            'Feb (02)',
            'Mar (03)',
            'Apr (04)',
            'May (05)',
            'Jun (06)',
            'Jul (07)',
            'Aug (08)',
            'Sep (09)',
            'Oct (10)',
            'Nov (11)',
            'Dec (12)',
         ],
         monthFormat: 'MMM',
      },
   },
};

const elementStyle = require('element-style');

// Returns true if it is a DOM element
function isElement(o: any) {
   return typeof HTMLElement === 'object'
      ? o instanceof HTMLElement
      : o && typeof o === 'object' && o !== null && o.nodeType === 1 && typeof o.nodeName === 'string';
}

/**
 * Finding out if the element is visible `threshold * 100`% in the viewport
 *
 * @param element {HTMLElement}
 * @returns {boolean}
 */
export function elementVisible(element: HTMLElement | Element) {
   let currentNode = element;

   if (!element || !isElement(element)) {
      throw new Error('Element is mandatory');
   }

   // @ts-ignore
   do {
      // Return false if our element is invisible
      if (
         elementStyle(currentNode, 'opacity') === '0' ||
         elementStyle(currentNode, 'display') === 'none' ||
         elementStyle(currentNode, 'visibility') === 'hidden'
      ) {
         return false;
      }
      // @ts-ignore
      // eslint-disable-next-line no-const-assign
   } while ((currentNode = currentNode.parentNode) !== document);

   // Otherwise
   return true;
}

export function formatDate(v: any): any {
   if (typeof v === 'string') {
      return new RegExp(/^[\d]{2}\.[\d]{2}\.[\d]{4}$/).test(v) ? moment(v, customFormatter.dateFormat).format('YYYY-MM-DD') : v;
   }

   if (Array.isArray(v)) {
      return v.map((vv: any) => formatDate(vv));
   }

   return v;
}

export function formatTime(time: any) {
   if (time != '' && time != null && typeof time != 'undefined' && typeof time != 'string') {
      /*if (typeof time === 'string' && time.includes(':') == false) {
         time = parseInt(time);
      } else {
         return time;
      }*/
      const dtFormat = new Intl.DateTimeFormat('en-GB', {
         timeZone: 'UTC',
         hour: '2-digit',
         minute: '2-digit',
      });

      return dtFormat.format(new Date(parseIntCustom(time) * 1e3));
   }
   return time;
}

export function formatStringTime(time: any) {
   if (time != '' && time != null && typeof time != 'undefined' && typeof time === 'string' && time.includes(':') == false) {
      time = parseIntCustom(time);
      const dtFormat = new Intl.DateTimeFormat('en-GB', {
         timeZone: 'UTC',
         hour: '2-digit',
         minute: '2-digit',
      });

      return dtFormat.format(new Date(parseIntCustom(time) * 1e3));
   }
   return time;
}

export function formatterDate(date: any, type: string | null = null) {
   if (typeof date == 'string' && parseFloatCustom(date).toString() == date) {
      date = parseFloat(date);
   }

   if (date != null && typeof date === 'number') {
      if (type == 'unix') {
         return moment.unix(date).format('YYYY-MM-DD');
      } else {
         return moment(new Date(date * 8.64e7), 'LLLL').format('YYYY-MM-DD');
      }
   }
   return date;
}

export function formatterFloat(value: number | string) {
   value = parseFloatCustom(value).toString();
   return parseFloat(value) !== 0 && value !== '0.00' ? numeral(value).format('0.00') : 0;
}

export function formatterNumber(val: any): number {
   val = parseFloatCustom(val);
   return isNaN(val) ? 0 : val;
}

export function formatterTime(val: string) {
   if (val.indexOf(':') < 0) val = val.substr(0, 2) + ':' + val.substr(2, 4);
   return val.length >= 5 && Time(val.substr(0, 5)).isValid() ? Time(val.substr(0, 5)).format('HH:mm') : '';
}

export function parseIntCustom(val: number | string): number {
   return parseInt(parseFloatCustom(val).toString());
}

export function parseFloatCustom(value: number | string): number {
   value = typeof value != 'string' && isNaN(value) ? 0 : value;
   value = typeof value == 'string' && !value.length ? 0 : value;
   value = typeof value == 'number' ? value.toString() : value;
   // eslint-disable-next-line no-useless-escape
   return parseFloat((value as string).replace(/[,\/бю]/gi, '.'));
}

export function findDate($filter: string, $value: string) {
   return !$filter || [null, '', 'Invalid date'].includes($filter) || moment($value).format('YYYYDDMM') === moment($filter).format('YYYYDDMM');
}

export function findColor($filter: string | string[], $value: string | number | any, $default: string = 'ffffff', $colors: any[] = []) {
   if (typeof $filter == 'string') {
      $filter = [$filter];
   }

   if (typeof $value == 'string' && /^\d+$/.test($value)) {
      $value = parseInt($value);
   }

   if (typeof $value == 'number' && $colors.length) {
      $value = first($colors.filter(c => c.id == $value))?.d_color_code as string;
   }

   if (typeof $value == 'number') {
      $value = $value.toString();
   }

   $value = ($value || '').toUpperCase().replace('#', '');
   $default = $default.toUpperCase().replace('#', '');
   $filter = $filter.map((c: any) => (typeof c !== 'string' ? c.hex : c).toUpperCase().replace('#', ''));

   //console.log($filter, $value, $default);

   return $filter.includes($value) || $filter.includes($default);
}

export const defaultColors: string[] = [
   '#FEFEFE',
   '#4D4D4D',
   '#999999',
   '#FFFFFF',
   '#F44E3B',
   '#FE9200',
   '#FCDC00',
   '#DBDF00',
   '#A4DD00',
   '#68CCCA',
   '#73D8FF',
   '#AEA1FF',
   '#FDA1FF',
   '#333333',
   '#808080',
   '#CCCCCC',
   '#D33115',
   '#E27300',
   '#FCC400',
   '#B0BC00',
   '#68BC00',
   '#16A5A5',
   '#009CE0',
   '#7B64FF',
   '#FA28FF',
   '#000000',
   '#666666',
   '#B3B3B3',
   '#9F0500',
   '#C45100',
   '#FB9E00',
   '#808900',
   '#194D33',
   '#0C797D',
   '#0062B1',
   '#653294',
   '#AB149E',
];
