import _Api from '@/api/_Api';

import { downloadBlob } from 'download.js';

import b64toBlob from 'b64-to-blob';

import {
   SuppliersResponse,
   ActSuppTransfGuidesResponse,
   FindGroupByRefParams,
   FindGroupByRefResponse,
   allActivInMotByUuidParams,
   allActivInMotByUuidResponse,
   CreateReportParams,
} from '@/interfaces/Activities';

export class ActivitiesApi extends _Api {
   static async getActSuppTransfGuidesData(): Promise<ActSuppTransfGuidesResponse> {
      const response = await this.http().get('activities/get-act-supp-transf-guides-data');
      return response.data;
   }

   static async all(): Promise<any> {
      const response = await this.http().get('activities');
      return response.data;
   }

   static async allObjects(): Promise<any> {
      const response = await this.http().get('activities/all-usable-data');
      return response.data;
   }

   static async suppliers(): Promise<SuppliersResponse> {
      const response = await this.http().get('activities/suppliers');
      return response.data;
   }

   static async transfers(params: any): Promise<SuppliersResponse> {
      const response = await this.http().get('activities/transfers', { params });
      return response.data;
   }

   static async dictionaries(): Promise<any> {
      const response = await this.http().get('activities/dictionaries');
      return response.data;
   }

   static async getCostTransf(params: any): Promise<any> {
      const response = await this.http().post('activities/get-cost-transfer', params);
      return response.data;
   }

   static async checkActivityAvailability(params: any): Promise<any> {
      const response = await this.http().post('activities/check-activity-availability', params);
      return response.data;
   }

   static async activityGraphic(params: any): Promise<any> {
      const response = await this.http().post('activities/activity-graphic', params);
      return response.data;
   }

   static async findGroupByRef(params: FindGroupByRefParams): Promise<FindGroupByRefResponse> {
      const response = await this.http().post('activities/find-group-by-ref', params);
      return response.data;
   }

   static async allActivInMotByUuid(params: allActivInMotByUuidParams): Promise<allActivInMotByUuidResponse> {
      const response = await this.http().post('activities/all-activ-in-moto-by-uuid', params);
      return response.data;
   }

   static async acceptActivity(params: any): Promise<any> {
      const response = await this.http().post('activities/accept-activity', params);
      return response.data;
   }

   static async rejectActivity(params: any): Promise<any> {
      const response = await this.http().post('activities/reject-activity', params);
      return response.data;
   }

   static async createReport(params: CreateReportParams): Promise<any> {
      const response = await this.http().post('activities/create-report', params);
      downloadBlob(response.data.filename, b64toBlob(response.data.base64, 'application/pdf'));
   }
}
