import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { HotelsApi } from '@/api/HotelsApi';

const state = {
   items: [],
   room_types: [],
   rooms: [],
};

const mutations: MutationTree<any> = {
   SET_HOTELS(state, items: any) {
      state.items = items;
   },
   SET_TYPES(state, colors) {
      state.room_types = colors;
   },
   setItems(state, { name, value }) {
      state[name] = value;
   },
};

const getters: GetterTree<any, any> = {};

const actions: ActionTree<any, any> = {
   GET_HOTELS({ commit, state }) {
      if (state.items.length) {
         return Promise.resolve(state.items.length);
      }
      return HotelsApi.all().then(value => {
         commit('setItems', { value, name: 'items' });
         return value.length;
      });
   },

   GET_ROOMS({ commit, state }) {
      if (state.rooms.length) {
         return Promise.resolve(state.rooms.length);
      }
      return HotelsApi.rooms().then(value => {
         commit('setItems', { value, name: 'rooms' });
         return value.length;
      });
   },

   GET_TYPES({ commit, state }) {
      if (state.room_types.length) {
         return Promise.resolve(state.room_types.length);
      }
      return HotelsApi.roomTypes().then(value => {
         commit('setItems', { value, name: 'room_types' });
         return value.length;
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
