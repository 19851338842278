import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { SettingsApi } from '@/api/SettingsApi';

const state = {
   items: [],
};

const mutations: MutationTree<any> = {
   SET_ITEMS(state, items: any) {
      state.items = items;
   },
};

const getters: GetterTree<any, any> = {};

const actions: ActionTree<any, any> = {
   SET_ITEMS({ commit, state }) {
      if (state.items.length) {
         return Promise.resolve(state.items.length);
      }
      return SettingsApi.beneficiaries().then(resp => {
         commit('SET_ITEMS', resp);
         return resp.length;
      });
   },
   GET_ACCOUNTS({ commit }, id: number) {
      return SettingsApi.beneficiary_account(id);
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
