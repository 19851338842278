import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { ActivitiesApi } from '@/api/ActivitiesApi';

import { orderBy } from 'lodash';

const state = {
   all: 0,
   dictionaries: 0,
   items: [],
   colors: [],
   paymentColors: [],
   room_types: [],
   invoice_states: [],
   guides: [],
   agents: [],
   rooms: [],
   drivers: [],
   beneficiaries: [],
   services: [],
   suppliers: [],
   currencies: [],
   vat: [],
   tariffication: [],
};

const mutations: MutationTree<any> = {
   SET_SUPPLIERS(state, items: any) {
      state.suppliers = orderBy(items, 'o_name');
   },

   SET_DICTIONARIES(state, dictionaries) {
      state.dictionaries = Object.keys(dictionaries).length;
      state.colors = dictionaries['Status'];
      state.paymentColors = dictionaries['Status Pay'];
      state.room_types = dictionaries['Room type'];
      state.invoice_states = dictionaries['invoice_state'];
      state.services = dictionaries['Service type'];
      state.currencies = dictionaries['currencies'];
      state.vat = dictionaries['VAT types'];
      state.tarification = dictionaries['tariffication'];
   },

   SET_ALL_OBJECTS(state, objects) {
      state.all = Object.keys(objects).length;
      state.items = objects['activities'];
      state.agents = objects['agents'];
      state.guides = objects['guides'];
      state.rooms = objects['rooms'];
      state.drivers = objects['drivers'];
      state.beneficiaries = objects['beneficiaries'];
   },
};

const getters: GetterTree<any, any> = {};

const actions: ActionTree<any, any> = {
   GET_SUPPLIERS({ commit, state }) {
      if (state.suppliers.length) {
         return Promise.resolve(state.suppliers.length);
      }
      return ActivitiesApi.suppliers().then(resp => {
         commit('SET_SUPPLIERS', resp);
         return resp.length;
      });
   },

   GET_DICTIONARIES({ commit, state }) {
      if (state.dictionaries != 0) {
         return Promise.resolve(state.dictionaries);
      }
      return ActivitiesApi.dictionaries().then(resp => {
         commit('SET_DICTIONARIES', resp);
         return Object.keys(resp).length;
      });
   },

   GET_ALL_OBJECTS({ commit, state }) {
      if (state.all != 0) {
         return Promise.resolve(state.all);
      }
      return ActivitiesApi.allObjects().then(resp => {
         commit('SET_ALL_OBJECTS', resp);
         return Object.keys(resp).length;
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
