import Vue from 'vue';
import { AxiosInstance } from 'axios';
import { localStorage } from '@/config/localStorage';

export default class _Api {
   static http(): AxiosInstance {
      return Vue.prototype.$http;
   }

   static setLang(lang: string): void {
      localStorage.setItem('language', lang);
      Vue.prototype.$moment.locale((lang === 'EN' ? lang : lang).toLowerCase());
      //Vue.prototype.$http.defaults.headers.common['x-language'] = lang;
      // @ts-ignore
      document.querySelector('html').setAttribute('lang', lang);
   }

   static setAuth(token: string | null): string | null {
      token = localStorage.setAuthToken(token);
      Vue.prototype.$http.defaults.headers.common['Authorization'] = token;
      return token;
   }
}
