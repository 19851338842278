import { ActionTree, MutationTree, GetterTree } from 'vuex';

import { StaffApi } from '@/api/StaffApi';

const state = {
   agents: [],
   drivers: [],
   guides: [],
   services: [],
};

const mutations: MutationTree<any> = {
   SET_ITEMS(state, { type, items }) {
      state[type] = items;
   },
};

const getters: GetterTree<any, any> = {};

const actions: ActionTree<any, any> = {
   SET_GUIDES({ commit, state }) {
      if (state.guides.length) {
         return Promise.resolve(state.guides.length);
      }
      return StaffApi.guides().then(resp => {
         commit('SET_ITEMS', { type: 'guides', items: resp });
         return resp.length;
      });
   },
   SET_AGENTS({ commit, state }) {
      if (state.agents.length) {
         return Promise.resolve(state.agents.length);
      }
      return StaffApi.agents().then(resp => {
         commit('SET_ITEMS', { type: 'agents', items: resp });
         return resp.length;
      });
   },
   SET_DRIVERS({ commit, state }) {
      if (state.drivers.length) {
         return Promise.resolve(state.drivers.length);
      }
      return StaffApi.drivers().then(resp => {
         commit('SET_ITEMS', { type: 'drivers', items: resp });
         return resp.length;
      });
   },
   SET_SERVICES({ commit, state }) {
      if (state.services.length) {
         return Promise.resolve(state.services.length);
      }
      return StaffApi.services().then(resp => {
         commit('SET_ITEMS', { type: 'services', items: resp });
         return resp.length;
      });
   },
};

export default {
   namespaced: true,
   state,
   getters,
   mutations,
   actions,
};
