import './assets/scss/style.scss';

import Vue from 'vue';

import App from '@/App.vue';

import router from '@/router';

import store from '@/store';

import localStorage from '@/config/localStorage';
Vue.use(localStorage);

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
Vue.use(BootstrapVue, {
   BTooltip: {
      delay: 1000,
   },
});
Vue.use(IconsPlugin);

import VueMeta from 'vue-meta';
Vue.use(VueMeta, { refreshOnceOnNavigation: true });

// @ts-ignore
import Loading from 'vue-loading-overlay';
Vue.use(Loading, {
   color: '#4662D4',
   container: null,
   canCancel: false,
   width: 64,
   height: 64,
});

import VueI18n from 'vue-i18n';
Vue.use(VueI18n);

// @ts-ignore
// import DateRangePicker from 'vue2-daterange-picker';
// Vue.component('DateRangePicker', DateRangePicker);

import Header from '@/components/Header.vue';
Vue.component('c-header', Header);

// @ts-ignore
import ColorPicker from '@/components/helpers/ColorPicker';
Vue.component('ColorPicker', ColorPicker);

import Logout from '@/components/Logout.vue';
Vue.component('Logout', Logout);

// @ts-ignore
import { ModelSelect } from 'vue-search-select';
Vue.component('ModelSelect', ModelSelect);

import InputTooltip from '@/components/InputTooltip.vue';
Vue.component('InputTooltip', InputTooltip);

import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed.js';
Vue.component('VuePdfEmbed', VuePdfEmbed);

import DatePickerRange from '@/components/helpers/DatePickerRange.vue';
Vue.component('DatePickerRange', DatePickerRange);

// @ts-ignore
import Datepicker from 'vue2-datepicker';
Vue.component('datepicker', Datepicker);

// @ts-ignore
import VueClipboards from 'vue-clipboards';
Vue.use(VueClipboards);

import CopyHelper from '@/components/CopyHelper.vue';
Vue.component('CopyHelper', CopyHelper);

const i18n = new VueI18n({
   locale: 'en',
   messages: {
      en: require('@/i18n/en.json'),
      lv: require('@/i18n/lv.json'),
      ru: require('@/i18n/ru.json'),
   },
});

// @ts-ignore
import VueMoment from 'vue-moment';

// @ts-ignore
import moment from 'moment-timezone';
moment.locale('en-gb', {
   week: {
      dow: 1,
   },
});
Vue.use(VueMoment, { moment });

import VeeValidate from 'vee-validate';
Vue.use(VeeValidate, {
   // This is the default
   inject: true,
   events: 'change|blur',
   // Important to name this something other than 'fields'
   fieldsBagName: '$veeFields',
   // This is not required but avoids possible naming conflicts
   errorBagName: '$veeErrors',
});

// @ts-ignore
// console.log(Vue.moment().locale());

// @ts-ignore
// import Cleave from '@/components/helpers/Cleave';
// Vue.component('Cleave', Cleave);

import CurrencyHelper from '@/components/helpers/Currency.vue';
Vue.component('CurrencyHelper', CurrencyHelper);

import Currency from 'vue-currency-filter';
Vue.use(Currency, {
   symbol: '€',
   fractionCount: 2,
   symbolSpacing: false,
   symbolPosition: 'back',
});

import http from '@/config/http';
Vue.use(http);

import { buildDropDown, formatDate } from '@/config/functions';
Vue.filter('buildDropDown', buildDropDown);
Vue.filter('formatDate', formatDate);

Vue.filter('reduce_sum', (items: any[], index: string | number) => {
   return items.length
      ? items
           .map(a => ((typeof a[index] == 'string' && a[index] != '') || typeof a[index] == 'number' ? parseFloat(a[index]) : 0))
           .reduce((a, b) => a + b)
      : 0;
});

Vue.filter('actWazeAppLink', (link: string) => {
   return link ? link.replace('https://www.waze.com/ru/live-map/directions?latlng', 'https://www.waze.com/ul?ll') : '';
});

import first from 'lodash/first';

Vue.filter('statusName', (colors: any[], status: string | number) => {
   if (typeof status == 'string') {
      status = status.replace('#', '');
      status = status.toUpperCase();
   }
   return colors ? first(colors.filter(c => [c.d_color_code.toUpperCase(), c.id].includes(status)))?.d_name.toUpperCase() : '';
});

Vue.filter('statusIcon', (colors: any[], status: string | number) => {
   if (typeof status == 'string') {
      status = status.replace('#', '');
      status = status.toUpperCase();
   }
   return colors ? first(colors.filter(c => [c.d_color_code.toUpperCase(), c.id].includes(status)))?.d_icon.toUpperCase() : '';
});

Vue.filter('statusIconShow', (colors: any[], status: string) => {
   return colors ? first(colors.filter(a => a.id == status))?.d_icon.toUpperCase() : '';
});

Vue.filter('getColor', (colors: any[], color: string) => {
   return '#' + (first(colors.filter((c: any) => c.id == color))?.d_color_code ?? 'ffffff');
});

Vue.filter('toFixed2', (value: string) => {
   return parseInt(value).toFixed(2);
});

Vue.config.productionTip = false;

new Vue({
   router,
   store,
   i18n,
   render: h => h(App),
}).$mount('#app');
