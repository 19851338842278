import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '../store/index';
import { localStorage } from '@/config/localStorage';

Vue.use(VueRouter);

function isAuth(to: any, from: any, next: any) {
   if (store.getters['users/isAuth']) {
      // @ts-ignore
      if (to.name !== 'activities' && ['archive', 'orders', 'calendar'].includes(to.name) && (store.state.users.roles as number[]).includes(38)) {
         next({ name: 'activities' });
      } else {
         next();
      }
   } else {
      if (document.URL != '') {
         localStorage.setItem('prevRoute', document.URL);
      }
      next({ name: 'auth' });
   }
}

function notAuth(to: any, from: any, next: any) {
   if (!store.getters['users/isAuth']) {
      next();
   } else {
      next({ name: 'calendar' });
   }
}

const routes: RouteConfig[] = [
   {
      path: '/',
      name: 'auth',
      component: () => import('@/views/Auth.vue'),
      beforeEnter: notAuth,
   },
   {
      path: '/calendar',
      name: 'calendar',
      component: () => import('@/views/booking/Calendar.vue'),
      beforeEnter: isAuth,
   },
   {
      path: '/activities',
      name: 'activities',
      component: () => import('@/views/activity/List.vue'),
      beforeEnter: isAuth,
   },
   {
      path: '/accommodations',
      name: 'accommodations',
      component: () => import('@/views/activity/Accommodation.vue'),
      beforeEnter: isAuth,
   },
   {
      path: '/transfers',
      name: 'transfers',
      component: () => import('@/views/activity/Transfers.vue'),
      beforeEnter: isAuth,
   },
   {
      path: '/check',
      name: 'check',
      props: true,
      component: () => import('@/views/activity/Check.vue'),
      beforeEnter: isAuth,
   },
   {
      path: '/orders',
      name: 'orders',
      component: () => import('@/views/booking/List.vue'),
      beforeEnter: isAuth,
   },
   {
      path: '/archive',
      name: 'archive',
      component: () => import('@/views/booking/List.vue'),
      beforeEnter: isAuth,
   },
   // {
   //    path: '/add',
   //    name: 'add.order',
   //    component: () => import('@/views/activity/Action.vue'),
   //    beforeEnter: isAuth,
   // },
   {
      path: '/edit/:id',
      name: 'edit.order',
      props: true,
      component: () => import('@/views/activity/Action.vue'),
      beforeEnter: isAuth,
   },
   {
      name: 'reports',
      path: '',
      component: () => import('@/views/activity/Reports.vue'),
      children: [
         {
            path: '/activities-report/:id',
            name: 'activities.report',
            component: () => import('@/views/activity/reports/ActivityReport.vue'),
         },
         {
            path: '/accommodation-report/:id',
            name: 'accommodation.report',
            component: () => import('@/views/activity/reports/AccommodationReport.vue'),
         },
      ],
   },
   {
      name: 'mobile',
      path: '/mobile',
      beforeEnter: isAuth,
      component: () => import('@/views/mobile/View.vue'),
      children: [
         {
            path: 'home',
            name: 'mobile.home',
            component: () => import('@/views/mobile/Index.vue'),
         },

         {
            path: 'groups',
            name: 'mobile.groups',
            component: () => import('@/views/mobile/Groups.vue'),
         },
         {
            path: 'activities',
            name: 'mobile.activities',
            component: () => import('@/views/mobile/Activities.vue'),
         },
         {
            path: 'hotels',
            name: 'mobile.hotels',
            component: () => import('@/views/mobile/Hotels.vue'),
         },
      ],
   },
];

export default new VueRouter({
   //mode: 'history',
   routes,
});
